import { useEffect, useState } from "react"
import AnimatedLetters from "../AnimatedLetters"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDocker, faHtml5, faJava, faJsSquare, faPython, faReact } from "@fortawesome/free-brands-svg-icons"
import './index.scss'
import { Loader } from "react-loaders"

const About = () => {

    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])
    return (
        <>
            <div className="container about-page">
            <div className="text-zone">
                <h1>
                    <AnimatedLetters
                        strArray={['Q', 'u', 'i', ' ', 's', 'u', 'i', 's', '-', 'j', 'e', ' ', '?']}
                        idx={15}
                        letterClass={letterClass}
                        />
                </h1>
                <p>Je suis un étudiant en école d'ingénieur.</p>
                <p>Je développe principalement en Java et python, mais j'ai aussi eu l'occasion d'effectuer de nombreux autres projets en Javascript, Kotlin, Swift, C#...</p>
                <p>Le site pour mobile n'est pas encore optimal. Il est en construction !</p>

            </div>

            <div className="stage-cube-cont">
                <div className='cubespinner'>
                    <div className="face1">
                        <FontAwesomeIcon icon={faJava} color="#E76F00"/>
                    </div>
                    <div className="face2">
                        <FontAwesomeIcon icon={faPython} color="#356F9F"/>
                    </div>
                    <div className="face3">
                        <FontAwesomeIcon icon={faReact} color="#5ED4F4"/>
                    </div>
                    <div className="face4">
                        <FontAwesomeIcon icon={faDocker} color="#086DD7"/>
                    </div>
                    <div className="face5">
                        <FontAwesomeIcon icon={faHtml5} color="#F06529"/>
                    </div>
                    <div className="face6">
                        <FontAwesomeIcon icon={faJsSquare} color="#EFD81D"/>
                    </div>
                </div>
            </div>
            </div>
            <Loader type="line-scale" />
        </>
        
    )
}

export default About
