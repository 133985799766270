import './index.scss'

import { Link, NavLink } from 'react-router-dom'
import logoS from '../../assets/logo-s.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faEnvelope, faHome, faUser } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

const Sidebar = () => (
    <div className='nav-bar'>
        <Link className='logo' to='/'>
            <img src={logoS} alt='logo' />
        </Link>
        <nav>
            <NavLink exact='true' to='/' activeClassName='active'>
                <FontAwesomeIcon icon={faHome} color='#fff'/>
            </NavLink>
            <NavLink exact='true' className="about-link" to='/about' activeClassName='active'>
                <FontAwesomeIcon icon={faUser} color='#fff'/>
            </NavLink>
            <NavLink exact='true' className="project-link" to='/projects' activeClassName='active'>
                <FontAwesomeIcon icon={faBook} color='#fff'/>
            </NavLink>
            <NavLink exact='true' className="contact-link" to='/contact' activeClassName='active'>
                <FontAwesomeIcon icon={faEnvelope} color='#fff'/>
            </NavLink>
        </nav>
        <ul>
            <li>
                <a target="_blank" rel='noreferrer' href='https://www.linkedin.com/in/tristan-hersent/'>
                    <FontAwesomeIcon icon={faLinkedin} color='#0A66C2'/>
                </a>
            </li>
            <li>
                <a target="_blank" rel='noreferrer' href='https://github.com/Tristanh80'>
                    <FontAwesomeIcon icon={faGithub} color='#fff'/>
                </a>
            </li>
        </ul>
    </div>
)


export default Sidebar