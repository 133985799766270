import './index.scss';
import LogoS from '../../../assets/logo-s.png'
import { useRef } from 'react';

const Logo = () => {

    const bgRef = useRef();
    const solidLogoRef = useRef();

    
    return (
        <div className='logo-container' ref={bgRef}>
            <img ref={solidLogoRef} className='solid-logo' src={LogoS} />
            
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            width="100%" viewBox="0 0 1200 1200" enable-background="new 0 0 1200 1200">
          <path fill="#022c43" opacity="1.000000" stroke="#ffd700" stroke-width="3.000000" stroke-miterlimit="10"
            d="
          M752.718018,355.195740 
            C769.399231,357.798340 785.648193,360.344604 801.902588,362.855804 
            C822.870972,366.095215 844.033997,365.516754 865.059021,364.722870 
            C882.319092,364.071198 899.188232,359.710205 915.459717,353.579224 
            C917.009827,352.995178 918.612244,352.545349 920.204651,352.082367 
            C920.491455,351.999023 920.848206,352.156372 922.196167,352.348267 
            C921.813904,357.428802 921.705688,362.620758 920.989197,367.727356 
            C918.181458,387.740601 911.210632,405.780731 895.259033,419.208771 
            C884.626892,428.158844 872.001282,433.281769 858.435425,434.618561 
            C845.814331,435.862274 832.961792,435.659668 820.267700,435.002625 
            C807.865479,434.360687 795.466248,432.768799 783.190247,430.812378 
            C768.586121,428.484924 754.116211,425.316650 739.583801,422.535614 
            C737.994873,422.231598 736.377014,422.078766 734.360229,421.798523 
            C731.587219,434.508850 728.869263,446.827209 726.213989,459.159058 
            C701.199585,575.333130 676.195312,691.509460 651.189453,807.685364 
            C648.212524,821.515625 645.203796,835.339172 642.308838,849.186523 
            C641.818848,851.530396 641.325806,853.068420 638.333313,853.054810 
            C607.674561,852.914856 577.015259,852.909851 546.356079,852.858521 
            C546.204224,852.858215 546.052612,852.732117 545.207153,852.356750 
            C576.804749,704.538086 608.435364,556.564880 639.975525,409.014893 
            C630.541870,409.014893 621.413879,408.486908 612.370789,409.137878 
            C598.594849,410.129578 585.087952,412.721832 572.075439,417.746704 
            C547.257080,427.330414 529.903564,444.554504 519.355408,468.726105 
            C512.044006,485.480591 508.125854,503.141968 507.070648,521.364563 
            C506.408569,532.797302 505.381836,544.343323 506.311340,555.691711 
            C507.071045,564.967102 510.433838,574.038025 512.763916,583.168640 
            C513.238403,585.027893 514.254272,586.749084 515.392517,589.400208 
            C491.178833,588.620056 468.864838,585.879028 453.525635,565.149170 
            C447.137451,556.515991 445.068909,546.447937 444.133423,536.065674 
            C437.977753,467.748627 473.129730,409.080048 531.547485,379.060028 
            C563.270630,362.757965 597.098633,354.212799 632.580444,352.024689 
            C647.975037,351.075348 663.393921,350.144409 678.809448,349.994629 
            C703.413025,349.755585 727.935547,351.404175 752.718018,355.195740 
          z"/>
          </svg>

        </div>
    )
}

export default Logo;