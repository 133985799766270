import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGitlab, faGithub } from '@fortawesome/free-brands-svg-icons';
import AnimatedLetters from '../AnimatedLetters';


const Projet = ({ icones, couleur, titre, description, openSourceLink, plateforme }) => {
  
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  
  return (
    <div className="projet">
      {icones.map((icone, index) => (
        <FontAwesomeIcon
          key={index}
          icon={icone}
          size="3x"
          color={couleur[index]}
          style={{ marginRight: '5px' }}
        />
      ))}
      <h3>
        <AnimatedLetters strArray={Array.from(titre)} letterClass={letterClass} idx={15} />
      </h3>
      <p>{description}</p>
      {openSourceLink && (
        <a href={openSourceLink} target="_blank" rel="noopener noreferrer">
          {plateforme === 'gitlab' ? (
            <FontAwesomeIcon icon={faGitlab} size='2x' color='#FC6D26'/>
          ) : (
            <FontAwesomeIcon icon={faGithub} size='2x' color='#FFFFFF'/>
          )}
        </a>
      )}
    </div>
  );
};

export default Projet;
