import React from 'react';
import { faJs, faJava, faDocker, faGitlab, faPython, faReact } from '@fortawesome/free-brands-svg-icons';
import { faC } from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import Projet from './project';
import Loader from 'react-loaders';

const ListeProjets = () => {
  const projets = [
    {
      icones: [faJava, faDocker, faGitlab],
      couleur: ['orange', 'blue', '#FC6D26'],
      titre: 'Studeam',
      description: "Backend d'une application de travail collaborative pour étudiants.\n Développement du backend en Java, du déploiement en Docker et de l'intégration continue avec Gitlab CI/CD.",
      
    },
    {
      icones: [faPython, faJs, faDocker],
      couleur: ['blue', 'yellow', 'blue'],
      titre: 'AutoMidjourney',
      description: "Automatisation de génération d'images avec Midjourney et chatGPT. Puis création de couverture de livres pour un lycée avec ses images."
    },
    {
      icones: [faJs],
      couleur: ['yellow'],
      titre: 'Scraping',
      description: "Création d'un script de scraping pour récupérer des données sur un site web."
    },
    {
      icones: [faJava, faDocker],
      couleur: ['orange', 'blue'],
      titre: 'Coaster lover',
      description: "Création d'un site web pour recenser des parcs d'attractions. Développement du backend en Java et du déploiement en Docker."
    },
    {
      icones: [faReact],
      couleur: ['#5ED3F3'],
      titre: 'tristanhersent.fr',
      description: "Création de mon portfolio avec React"
    },
    {
      icones: [faC],
      couleur: ['white'],
      titre: 'Shotopof',
      description: "Création d'un photoshop lite pour un projet d'école",
      openSourceLink: 'https://github.com/Tristanh80/CRIT',
      plateforme: 'github'
    },
    // ,
    //   openSourceLink: 'https://gitlab.com/votre-utilisateur/votre-projet',
    //   plateforme: 'gitlab'
    // ...
  ];
  

  return (
    <>
    <div className="container">
      <div className="liste-projets">
      {projets.map((projet, index) => (
        <Projet
          key={index}
          icones={projet.icones}
          couleur={projet.couleur}
          titre={projet.titre}
          description={projet.description}
          openSourceLink={projet.openSourceLink}
          plateforme={projet.plateforme}
        />
      ))}
      </div>
    </div>
    <Loader type="line-scale" />

    </>
    
  );
};


export default ListeProjets;
