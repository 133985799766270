import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import Recaptcha  from 'react-google-recaptcha'

const Contact = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const [captcha, setCaptcha] = useState(false)
    const form = useRef()

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    const successCaptcha = () => {
        setCaptcha(true)
    }

    const resetCaptcha = () => {
        setCaptcha(false)
    }

    const captchaError = () => {
        alert('Une erreur est survenue, probablement à cause de votre connexion internet, veuillez réessayer.')
    }

    const sendEmail = (e) => {
        e.preventDefault()
        if (!captcha) {
            alert('Veuillez valider le captcha.')
            return
        }

        emailjs
            .sendForm(
                'service_3suad1d',
                'template_mh8aapb',
                form.current,
                "Yb3oOwbKtdbyC1U5L"
            )
            .then(
                () => {
                    alert('Message envoyé !')
                    window.location.reload(false)
                },
                (erreur) => {
                    console.log(erreur)
                    alert('Une erreur est survenue, veuillez réessayer.')
                }
            )
    }


    return (
        <>
            <div className='container contact-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters letterClass={letterClass}
                            strArray={['C', 'o', 'n', 't', 'a', 'c', 't', 'e', 'z', '-', 'm', 'o', 'i']} 
                            idx={15} />
                    </h1>
                    <p>
                        Je suis ouvert à toute proposition de travail, n'hésitez pas à me contacter.
                    </p>
                    <div className='contact-form'>
                        <form ref={form} onSubmit={sendEmail}>
                            <ul>
                                <li className='half'>
                                    <input type='text' name="name" placeholder='Nom' required/>
                                </li>
                                <li className='half'>
                                    <input type='email' name="email" placeholder='Email' />
                                </li>
                                <li>
                                    <input placeholder='Sujet' type="text" name="subject" required />
                                </li>
                                <li>
                                    <textarea placeholder='Message' name="message" required></textarea>
                                </li>
                                <li className='captcha'>
                                    <Recaptcha 
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    onChange={successCaptcha}
                                    onExpired={resetCaptcha}
                                    onErrored={captchaError}
                                     />
                                </li>
                                <li>
                                    <input type='submit' className='flat-button' value="Envoyer" />
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
            <Loader type="line-scale" />
        </>
    )
}

export default Contact