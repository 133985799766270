import { Link } from 'react-router-dom'
import Logotitle from '../../assets/logo-s.png'
import './index.scss'
import { useEffect, useState } from 'react'
import AnimatedLetters from '../AnimatedLetters'
import Logo from './Logo'
import Loader from 'react-loaders'

const Home = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['r', 'i', 's', 't', 'a', 'n', ',']
    const jobArray = ['d', 'é', 'v', 'e', 'l', 'o', 'p', 'p', 'e', 'u', 'r', ' ', 'f', 'u', 'l', 'l', 's', 't', 'a', 'c', 'k']

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000)
    }, [])

    return (
        <>
            <div className="container home-page">
            <div className="text-zone">
                <h1 className='animated'>
                <span className={letterClass}>B</span> 
                <span className={`${letterClass} _12`}>o</span>
                <span className={`${letterClass} _13`}>n</span>
                <span className={`${letterClass} _14`}>j</span>
                <span className={`${letterClass} _15`}>o</span>
                <span className={`${letterClass} _16`}>u</span>
                <span className={`${letterClass} _17`}>r</span>
                <br />
                <span className={`${letterClass} _13`}>J</span> 
                <span className={`${letterClass} _14`}>e&nbsp;</span>
                <span className={`${letterClass} _15`}>m'</span>
                <span className={`${letterClass} _16`}>a</span>
                <span className={`${letterClass} _17`}>p</span>
                <span className={`${letterClass} _18`}>p</span>
                <span className={`${letterClass} _19`}>e</span>
                <span className={`${letterClass} _20`}>l</span>
                <span className={`${letterClass} _21`}>l</span>
                <span className={`${letterClass} _22`}>e</span>
                <img src={Logotitle} alt="developper" />
                <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={23} />
                <br />
                <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={15} />
                </h1>
                <h1 className='noAnimated'>
                    Bonjour, je m'appelle Tristan, <br />
                    je suis développeur fullstack
                </h1>

                <h2>Java / Python / Kotlin / Javascript / Node</h2>
                <Link to="/contact" className="flat-button">Contactez moi</Link>
            </div>
            <Logo />
            </div>
            <Loader type="line-scale" />
        </>
        
        
    )
}

export default Home
